import React from 'react';

const MortgageEarly = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Don’t just be swayed by the best interest rate, any other fees and how much you can afford in monthly payments should also be taken into account</li>
              <li>
              Look at the overpayment threshold and terms and see if you can make overpayments where you can
              </li>
              <li>
              Plan ahead, you can remortgage up to six months ahead of your deal expiring to avoid being moved to a standard-variable rate
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Tips to help you save money on your mortgage.<br/><br/>
              The first tip to mention is that when choosing between different mortgage products, don't just be swayed by the best interest rate. It's important to factor in any associated fees or incentives that may come with the mortgage product.<br /><br/>
              A mortgage adviser can help you to ensure you're getting the most competitive product therefore not wasting money.<br /><br/>
              Most mortgage products also allow you to overpay the mortgage, meaning you can make payments against your mortgage above your usual monthly payment. You can either make regular monthly overpayments or make ad hoc payments whenever you have some spare cash.<br /><br/>
              Whichever overpayment works for you both will mean you save interest overall. You'd be amazed by paying just a little bit each month what a big difference it can make. My final tip to save money on your mortgage is to plan ahead.<br /><br/>
              Most mortgages have a date when the fixed-rate or the discount rate period comes to an end. After that, the mortgage will revert to the standard variable rate, which will mean a higher interest rate.<br /><br/>
              How can you avoid paying that higher rate? Well, typically, you can arrange a new mortgage up to six months ahead of when your current deal ends.<br /><br/>
              By getting that mortgage in place, you'll avoid that last-minute rush and avoid spending time on the variable rate, thus saving you money.<br/><br /><br/>
            </p>
          </div>
    </div>
  );
};

export default MortgageEarly;
